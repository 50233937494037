:host, :root {
	--step: 4px;
	
	--sidebar: 260px;
	--container-padding: 0px;

	--neutral-000: #FFFFFF;
	--neutral-050: #F9FAFB;
	--neutral-100: #F3F4F6;
	--neutral-200: #E5E7EB;
	--neutral-300: #D1D5DB;
	--neutral-400: #9CA3AF;
	--neutral-500: #6B7280;
	--neutral-600: #4B5563;
	--neutral-700: #374151;
	--neutral-800: #1F2937;
	--neutral-900: #111827;
	--neutral-950: #030712;

	--neutral-dim: rgba(217, 222, 223, 0.3);

	--select: #1476FF10;

	--primary: #1476FF;
	--error: #EF6F6C;
	--success: #7ECA63;
}

/* main */
.mapplic-element {
	color: var(--neutral-900);
	font-size: 14px;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.mapplic-element:fullscreen {
	background-color: var(--neutral-000);
}

.mapplic-element:fullscreen > .mapplic-container {
	height: 100% !important;
	aspect-ratio: auto !important;
	border-radius: 0 !important;
}

/* kiosk mode */
.mapplic-kiosk .mapplic-element > .mapplic-element {
	height: 100vh !important;
}

.mapplic-kiosk .mapplic-element > .mapplic-container {
	aspect-ratio: auto !important;
	border-radius: 0 !important;
}

/* loader & 404 */
.mapplic-placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: var(--neutral-100);
	aspect-ratio: 1.6;
	border-radius: 12px;
}

.mapplic-loader {
	width: 16px;
	height: 16px;
	border: 2px solid var(--neutral-900);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	opacity: 0.6;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* right sidebar */
.mapplic-element.mapplic-sidebar-right > .mapplic-sidebar {
	left: calc(100% - var(--sidebar));
	padding: 0 0 0 8px;
}

/* closed or no sidebar */
/*.mapplic-element.mapplic-sidebar-toggle.mapplic-sidebar-closed .mapplic-sidebar > *:not(.mapplic-search-bar) { opacity: 0; }*/
.mapplic-element.mapplic-sidebar-toggle.mapplic-sidebar-closed .mapplic-sidebar {
	pointer-events: none;
}

.mapplic-element.mapplic-sidebar-toggle.mapplic-sidebar-closed .mapplic-search-bar {
	pointer-events: auto;
}

/* portrait */
.mapplic-portrait .mapplic-sidebar {
	position: relative;
	left: 0 !important;
	width: 100%;
	max-width: 100%;
	padding: 8px 0 0 0 !important;
	transition: margin-top 0.2s, max-width 0.2s;
}
.mapplic-portrait .mapplic-container {
	margin: 0 !important;
	max-width: 100%;
}

.mapplic-portrait.mapplic-sidebar-closed .mapplic-sidebar {
	margin-top: -86px;
	max-width: 240px;
}

/* basic elements */
.mapplic-element h4,
.mapplic-element h5 {
	line-height: 1.8em;
	margin: 0 !important;
}

.mapplic-element h1,
.mapplic-element h2,
.mapplic-element h3,
.mapplic-element h4,
.mapplic-element h5 {
	margin-top: 0;
	margin-bottom: 0;
}

.mapplic h3 {
	font-size: 20px;
	line-height: 1.2em;
	font-weight: 600;
}

.mapplic p {
	line-height: 1.6em;
	margin: 0;
}

/* container */
.mapplic-container {
	background-color: var(--neutral-050);
	flex-shrink: 0;
	padding: var(--container-padding);
	box-sizing: border-box;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
}

.mapplic-container > img {
	display: block;
	max-width: 100%;
}

.mapplic-control-zone {
	position: absolute;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	max-width: 40%;
	max-height: 40%;
	display: inline-flex;
	align-items: baseline;
	gap: 8px;
	flex-direction: column;
	transition: top 0.2s, bottom 0.2s;
}

.mapplic-control-zone > * {
	pointer-events: auto;
}

.mapplic-control-zone.mapplic-top-left {
	top: 16px;
	left: 16px;
}

.mapplic-control-zone.mapplic-top-right {
	top: 16px;
	right: 16px;
	align-items: flex-end;
}

.mapplic-control-zone.mapplic-bottom-right {
	right: 16px;
	bottom: 16px;
	align-items: flex-end;
	flex-direction: column-reverse;
}

.mapplic-control-zone.mapplic-bottom-left {
	bottom: 16px;
	left: 16px;
	flex-direction: column-reverse;
}

.mapplic-marker {
	top: 50%;
	left: 50%;
}

/* markers */
.mapplic-marker {
	background-color: var(--primary);
	border-radius: 50%;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	position: absolute;
	line-height: 20px;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	text-decoration: none;
	transition: background-color 0.2s;
}

.dragging .mapplic-marker {
	opacity: 0.3 !important;
}

.mapplic-marker:hover {
	opacity: 0.6;
	z-index: 101 !important;
}

.mapplic-marker > span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.mapplic-marker.mapplic-active {
	z-index: 101 !important;
}

.mapplic-marker.square { border-radius: 0; }
.mapplic-marker.rounded { border-radius: 6px; }
.mapplic-marker.dot {
	font-size: 0;
	width: 12px;
	height: 12px;
	margin-left: -6px;
	margin-top: -6px;
}

.mapplic-marker.pin1 {
	background-color: transparent !important;
	width: 22px;
	height: 26px;
	margin-left: -11px;
	margin-top: -26px;
	transform-origin: 50% 100%;
}

.mapplic-marker.pin2 {
	background-color: transparent !important;
	width: 21px;
	height: 28px;
	margin-left: -10.5px;
	margin-top: -28px;
	transform-origin: 50% 100%;
}

.mapplic-marker.thumb {
	background-color: transparent !important;
	border-radius: 4px;
	overflow: hidden;
	width: 24px;
	height: 24px;
	line-height: 24px;
	margin-left: -12px;
	margin-top: -12px;
	transform-origin: 50% 50%;
}

.mapplic-marker.thumb > img {
	width: 100%;
	height: 100%;
}

.mapplic-marker.text > span.title {
	white-space: nowrap;
	width: auto;
	margin-left: 24px;
}

/* dim */
.mapplic-dim {
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
}

/* popup */
.mapplic-popup-content {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 60%;
	padding: 12px;
	gap: 12px;
	max-width: 100%;
}

.mapplic-popup-title {
	margin-right: 24px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.mapplic-popup-title h4 {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
}

.mapplic-popup-title h5 {
	display: flex;
	gap: 4px;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: var(--neutral-500);
}

.mapplic-popup-close {
	all: unset;
	cursor: pointer;
	color: var(--neutral-900);
	padding: 4px;
	opacity: 0.5;
	height: 20px;
	width: 20px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 8px;
	top: 8px;
	transition: opacity 0.2s;
}

.mapplic-popup-close:hover { opacity: 1; }

.mapplic-popup-image {
	flex-basis: 40%; 
	flex-grow: 0;
}

.mapplic-tooltip .mapplic-popup-image {
	overflow: hidden;
	border-radius: 8px 0 0 8px;
	background-color: var(--neutral-050);
}

.mapplic-popup-image > img {
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
}

.mapplic-popup-body {
	flex-grow: 1;
	justify-content: space-between;
	min-width: 100px;
	font-size: 13px;
	color: var(--neutral-700);
	line-height: 1.4em;
	overflow-y: auto;
}

.mapplic-popup-body > * {
	max-width: 100%;
}

.mapplic-popup-body p:first-child { margin-top: 0; }
.mapplic-popup-body p:last-child { margin-bottom: 0; }

.mapplic-popup-actions {
	display: flex;
	gap: 8px;
}

.mapplic-popup-details {
	border-radius: 6px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	background-color: var(--neutral-100);
}

.mapplic-phone {
	color: var(--primary);
	font-weight: bold;
	width: 100%;
	text-align: center;
	text-decoration: none;
}

.mapplic-phone:hover {
	text-decoration: underline;
}

.mapplic-hours {
	display: flex;
	flex-direction: column;
	gap: 2px;
	font-size: 13px;
}

.mapplic-popup-footer {
	display: flex;
	gap: 8px;
	justify-content: space-between;
}

.mapplic-sidebar-popup {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: 4px;
}

.mapplic-sidebar-popup h4 {
	font-size: 26px;
	line-height: 32px;
}

.mapplic-sidebar-popup .mapplic-popup-body {
	font-size: 14px;
	line-height: 22px;
}

.mapplic-popup-micro h4 {
	font-size: 16px;
	text-align: center;
}

.mapplic-popup-micro .mapplic-popup-title {
	margin-right: 0;
}

/* tooltip */
.mapplic-tooltip {
	background-color: var(--neutral-000);
	border-radius: 8px;
	display: flex;
	cursor: auto;
	position: absolute;
	transform: translate(-50%, -100%) !important;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
	z-index: 10002;
	width: max-content;
	text-align: left;
}

.mapplic-tooltip:after {
	content:'';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -8px;
	margin-top: -1px;
	width: 0;
	height: 0;
	border-top: solid 8px var(--neutral-000);
	border-left: solid 8px transparent;
	border-right: solid 8px transparent;
}

.mapplic-tooltip-hover {
	pointer-events: none !important;
}

.mapplic-tooltip-hover .mapplic-tooltip-content {
	padding: 8px;
	min-width: auto;
}

.mapplic-tooltip-image {
	border-radius: 6px 0 0 6px;
	overflow: hidden;
	max-width: 40%;
	min-width: 40%;
	background-color: var(--neutral-100);
}

.mapplic-tooltip-image > img {
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
}

.mapplic-tooltip-content {
	flex-grow: 1;
	justify-content: space-between;
	padding: 12px;
	min-width: 100px;
}

.mapplic-tooltip-content p {
	line-height: 1.4em;
}

.mapplic-tooltip-content p:first-child { margin-top: 0; }
.mapplic-tooltip-content p:last-child { margin-bottom: 0; }

.mapplic-tooltip-close {
	background: none;
	border: none;
	cursor: pointer;
	color: var(--neutral-900);
	padding: 4px;
	opacity: 0.5;
	height: 20px;
	width: 20px;
	position: absolute;
	right: 8px;
	top: 8px;
	transition: opacity 0.2s;
}

.mapplic-tooltip-close:hover {
	opacity: 1;
}

.mapplic-tooltip-content {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.mapplic-tooltip:not(.mapplic-tooltip-hover) .mapplic-tooltip-title {
	margin-right: 16px;
}

.mapplic-tooltip-title h4 {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.mapplic-tooltip-title h5 {
	display: flex;
	gap: 4px;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: var(--neutral-500);
}

.mapplic-tooltip-desc {
	line-height: 20px;
	font-weight: 400;
	overflow-y: auto;
}

.mapplic-tooltip-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
}

.mapplic-tooltip-footer code {
	background-color: var(--neutral-300);
	border-radius: 4px;
	font-weight: bold;
	padding: 4px 6px;
}

/* controls */
.mapplic-controls {
	padding: 16px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
}


.mapplic-controls .mapplic-sidebar-close {
	background-color: var(--neutral-000);
	border-radius: 0 16px 16px 0;
	border: none;
	cursor: pointer;
	position: absolute;
	pointer-events: all;
	min-width: 24px;
	height: 32px;
	font-size: 0;
	z-index: 110;
	color: var(--neutral-600);
	transition: color 0.2s;
	
	transform: translateY(-50%);
	left: 0;
	padding: 7px 7px 7px 0;
	top: 50%;
}

.mapplic-sidebar-closed .mapplic-controls .mapplic-sidebar-close > svg {
	transform: rotate(180deg);
}

.mapplic-controls .mapplic-sidebar-close:hover {
	color: var(--neutral-900);
}

.mapplic-sidebar-right .mapplic-controls .mapplic-sidebar-close {
	left: auto;
	right: 0;
	transform: translateY(-50%) rotate(180deg);
	top: 50%;
}

.mapplic-portrait .mapplic-controls .mapplic-sidebar-close {
	top: auto;
	right: auto;
	left: 50%;
	bottom: -4px;
	transform: translateX(-50%) rotate(-90deg);
}

.mapplic-portrait .mapplic-sidebar-close > svg {
	transform: rotate(180deg);
}

.mapplic-control-button {
	pointer-events: auto;
	background-color: var(--neutral-000);
	border: 1px solid var(--neutral-200);
	border-radius: 6px;
	box-sizing: border-box;
	color: var(--neutral-900);
	cursor: pointer;
	display: flex;
	padding: 7px;
	transition: box-shadow 0.2s;
	gap: 8px;
}

.mapplic-control-button > span {
	font-size: 14px;
	line-height: 16px;
}

.mapplic-controls .mapplic-control-button:not(:disabled):hover {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}

.mapplic-controls .mapplic-control-button:active {
	box-shadow: none;
}

.mapplic-control-button:disabled {
	background-color: var(--neutral-000);
	color: var(--neutral-400);
	cursor: auto;
}

.mapplic-control-group > .mapplic-control-button { border-radius: 0; }
.mapplic-control-group > .mapplic-control-button:first-child { border-radius: 6px 6px 0 0; }
.mapplic-control-group > .mapplic-control-button:last-child { border-radius: 0 0 6px 6px; }

/* button */
.mapplic-button {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
	background-color: var(--neutral-000);
	border: 1px solid var(--neutral-300);
	border-radius: 6px;
	cursor: pointer;
	color: var(--neutral-900);
	display: inline-flex;
	justify-content: center;
	gap: 6px;
	text-decoration: none;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
	transition: background-color 0.2s, border-color 0.2s;
}

.mapplic-button:disabled {
	color: var(--neutral-400);
}

.mapplic-button:not(:disabled):hover {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
	background-color: var(--neutral-000);
	border-color: var(--neutral-400);
}

.mapplic-button:not(:disabled).mapplic-active,
.mapplic-button:not(:disabled):active {
	box-shadow: none;
	background-color: var(--neutral-200);
}

.mapplic-button-small {
	background-color: var(--primary);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	line-height: 10px;
	color: #fff;
	text-decoration: none;
	padding: 4px;
}

.mapplic-button.mapplic-button-primary:not(:disabled) {
	background-color: var(--primary);
	border: none;
	padding: 8px 12px;
	color: #fff;
	font-weight: 600;
	transition: filter 0.2s;
}

.mapplic-button.mapplic-button-icon {
	padding: 8px;
}

.mapplic-button.mapplic-button-primary:not(:disabled):hover { filter: brightness(1.2); }
.mapplic-button.mapplic-button-primary:not(:disabled):active { filter: brightness(0.8); }
.mapplic-button.mapplic-button-primary:disabled {
	background-color: var(--neutral-300);
	border: none;
	color: var(--neutral-000);
	cursor: auto;
	font-weight: bold;
}

.mapplic-button.mapplic-button-secondary {
	background-color: var(--neutral-700) !important;
	border: 1px solid var(--neutral-700) !important;
	color: var(--neutral-000);
}

/* image */
.mapplic-image {
	border-radius: 8px;
	overflow: hidden;
	font-size: 0;
	margin-bottom: 12px;
	height: 140px;
}

.mapplic-image > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

/* layer */
.mapplic-layers {
	min-width: 100%;
	position: relative;
	transform-origin: top left;
}

.mapplic-panzoom {
	touch-action: none;
	position: relative;
}

.mapplic-layer {
	line-height: 0;
	width: 100%;
	height: 100%;
	position: relative;
}

.mapplic-panzoom .mapplic-layer {
	width: auto;
	height: auto;
	position: absolute;
}

.mapplic-layer > img {
	display: block;
}

.mapplic-layer > img:not(.mapplic-panzoom .mapplic-layer > img) {
	width: 100%;
	height: 100%;
}

.mapplic-layer > svg {
	width: 100%;
	height: 100%;
}

.mapplic-panzoom .mapplic-layer > svg {
	width: auto;
	height: auto;
}

.mapplic-overlay {
	box-sizing: border-box;
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.mapplic-overlay > * {
	pointer-events: auto;
}

.mapplic-layer-switcher {
	display: flex;
	flex-direction: column;
}

.mapplic-layer-switcher > button {
	background-color: rgba(242, 242, 242, 0.6);
	border-radius: 0;
	color: var(--neutral-700);
	cursor: pointer;
	padding: 0 8px;
	min-height: 28px;
	font-size: 12px;
	font-weight: 600;
	border: none;
	transition: transform 0.2s;
}

.mapplic-layer-switcher > button:first-child { border-radius: 6px 6px 0 0; }
.mapplic-layer-switcher > button:last-child { border-radius: 0 0 6px 6px; }

.mapplic-layer-switcher > button.mapplic-active {
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.02);
	background-color: var(--neutral-000);
	border-radius: 4px;
	color: var(--neutral-900);
	transform: scale(1.2);
	z-index: 100;
}

/* sidebar */
.mapplic-sidebar {
	box-sizing: border-box;
	position: absolute;
	top:0;
	left:0;
	bottom:0;

	pointer-events: none;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: var(--sidebar);
	padding: 0 8px 0 0;
	overflow: hidden;
	z-index: 110;

	transition: left 0.2s;
}

.mapplic-sidebar > * { pointer-events: auto; }

.mapplic-sidebar-count {
	color: var(--neutral-600);
	margin: 0 8px 8px 8px;
}

/* directory */
.mapplic-dir {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 24px;
	padding: 0 4px;
	margin: 8px 0;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.mapplic-dir-items {
	display: grid;
	grid-template-columns: 100%;
	max-width: 100%;
	gap: 4px;
	margin: 0;
	padding: 0;
	list-style: none;
}

.mapplic-dir-items > li {
	min-width: 0;
}

.mapplic-grid-items {
	gap: 16px;
}

.mapplic-dir-group-title {
	color: var(--neutral-300);
	display: flex;
	justify-content: space-between;
	gap: 12px;
	align-items: center;
	padding: 8px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	transition: color 0.2s;
}

.mapplic-dir-group-title > span {
	flex-shrink: 0;
	font-size: 11px;
}

.mapplic-line {
	height: 0.8px;
	background-color: var(--neutral-100);
	transition: background-color 0.2s;
	width: 100%;
}

.mapplic-dir-group:hover > .mapplic-dir-group-title {
	color: var(--neutral-500);
}

.mapplic-dir-item {
	box-shadow: none;
	all: unset;
	border: 1px solid transparent;
	border-radius: 8px;
	color: var(--neutral-900);
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
	text-decoration: none;
	padding: 6px;
	transition: border-color 0.2s, background-color 0.2s, box-shadow 0.2s;
}

.mapplic-grid-item {
	flex-direction: column;
	align-items: flex-start;
}

.mapplic-grid-item h3 {
	font-weight: 600;
	font-size: 16px;
}

.mapplic-grid-item > .mapplic-thumbnail {
	width: 100%;
	height: auto;
	aspect-ratio: 3/2;
}

.mapplic-grid-item > .mapplic-item-body {
	padding: 4px 8px;
	width: 100%;
}

.mapplic-dir-item.mapplic-active {
	border-color: var(--neutral-100);
}

.mapplic-dir-item.mapplic-active .mapplic-thumbnail {
	opacity: 1;
}

.mapplic-thumbnail {
	background-color: var(--neutral-200);
	box-sizing: border-box;
	border-radius: 4px;
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	overflow: hidden;
	opacity: 0.5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	transition: opacity 0.2s, background-color 0.2s;
}

.mapplic-dir-item .mapplic-thumbnail:has(img),
.mapplic-dir-item:hover .mapplic-thumbnail:has(img),
.mapplic-dir-item.mapplic-active .mapplic-thumbnail:has(img) {
	background-color: var(--neutral-000);
}

.mapplic-grid-item .mapplic-thumbnail:has(img) {
	background-color: var(--neutral-050);
}

.mapplic-grid-item .mapplic-thumbnail:has(img) {
	padding: 5%;
}

.mapplic-thumbnail > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.2s;
}

.mapplic-thumbnail > span {
	color: var(--neutral-000);
	display: block;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}

.mapplic-dir-item.mapplic-highlight .mapplic-thumbnail,
.mapplic-dir-item:hover .mapplic-thumbnail {
	opacity: 1;
}

.mapplic-dir-item.mapplic-highlight,
.mapplic-dir-item:hover,
.mapplic-dir-item:focus-visible {
	background-color: var(--neutral-000);
	border-color: var(--neutral-200);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.02);
	outline: none;
}

.mapplic-item-body {
	display: flex;
	flex-direction: column;
	gap: 2px;
	overflow: hidden;
	max-width: 100%;
}

.mapplic-dir-item h4, h5 {
	line-height: 20px;
	margin: 0;
}

.mapplic-dir-item h4 {
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.mapplic-dir-item h5 {
	display: flex;
	gap: 4px;
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
	color: var(--neutral-600);
	font-size: 12px;
	font-weight: normal;
}

.mapplic-empty-message {
	color: var(--neutral-500);
	font-size: 13px;
	margin: auto;
	padding: 0 8px;
}

/* filters */
.mapplic-search-filter {
	border-radius: 12px;
	border: 3px solid transparent;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	width: 100%;
	max-width: 400px;
	overflow: hidden;
	flex-shrink: 0;
	transition: border-color 0.2s;
}

.mapplic-portrait .mapplic-search-filter {
	margin: 16px 0;
}

.mapplic-search-filter.opened {
	background-color: var(--neutral-000);
	border-color: var(--neutral-100);
}

.mapplic-filters-footer {
	background-color: var(--neutral-100);
	display: flex;
	justify-content: space-between;
	padding: 8px 12px 5px 12px;
}

.mapplic-filters-footer > button {
	all: unset;
	font-weight: normal;
	cursor: pointer;
	display: flex;
	gap: 4px;
	align-items: center;
}

.mapplic-filters-footer > span {
	font-size: 13px;
	color: var(--neutral-400);
}

/* search */
.mapplic-search-bar {
	display: flex;
	gap: 8px;
	padding: 9px; /* 12 - border */
}

.mapplic-search-bar .mapplic-search { flex-grow: 1; }
.mapplic-search-bar .mapplic-switch { flex-shrink: 0; }

.mapplic-search {
	background-color: rgba(242, 242, 242, 0.6);
	border-radius: 8px;
	position: relative;
}

.mapplic-search > input {
	background: none;
	border: none;
	border-radius: 8px;
	box-sizing: border-box;
	color: var(--neutral-900);
	font-size: 16px !important;
	line-height: 20px !important;
	padding: 10px;
	padding-left: 36px;
	width: 100%;
}

.mapplic-search > input:focus {
	outline: 2px solid var(--primary);
}

.mapplic-search > svg {
	position: absolute;
	left: 12px;
	top: 50%;
	margin-top: -8px;
}

.mapplic-search > button {
	background: none;
	border: none;
	color: var(--neutral-900);
	cursor: pointer;
	line-height: 8px;
	right: 8px;
	top: 50%;
	margin-top: -12px;
	padding: 6px;
	position: absolute;
}

.mapplic-element mark {
	background-color: #1476FF40;
	color: var(--neutral-900);
}

/* filters */
.mapplic-filters {
	background-color: var(--neutral-100);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.mapplic-filters-body {
	background-color: var(--neutral-000);
	border-radius: 0 0 8px 8px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex-shrink: 2;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 8px 16px 16px 16px;
}

.mapplic-filters h5 {
	color: var(--neutral-400);
	font-weight: 500;
	font-size: 11px;
	line-height: 1.6em;
	margin-bottom: 8px;
	margin-top: 8px;
	text-transform: uppercase;
}

.mapplic-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	padding: 4px 0;
}

.mapplic-tag {
	background-color: var(--neutral-000);
	border: 1px solid var(--neutral-200);
	border-radius: 13px;
	color: var(--neutral-600);
	cursor: pointer;
	display: flex;
	font-size: 12px;
	line-height: 100%;
	font-weight: 600;
	padding: 6px 8px;
	vertical-align: middle;
	/*text-transform: uppercase;*/
	transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.mapplic-tag:not(.mapplic-active) > span {
	color: var(--neutral-800);
}

.mapplic-tag:hover {
	border-color: var(--neutral-500);
}

.mapplic-tag.mapplic-active {
	background-color: var(--neutral-800);
	border-color: var(--neutral-800);
}

.mapplic-tag > svg {
	vertical-align: middle;
}

.mapplic-tag > svg:first-child { margin-right: 2px; }
.mapplic-tag > svg:last-child { margin-left: 2px; }

/* dropdown */
.mapplic-dropdown {
	background-color: var(--neutral-000);
	border: 1px solid var(--neutral-300);
	border-radius: 6px;
	margin: 0;
	box-sizing: border-box;
	font-size: 16px !important;
	line-height: 20px !important;
	margin-bottom: 8px;
	padding: 8px;
	width: 100%;
}

/* elements */
.mapplic-count {
	background-color: var(--neutral-800);
	box-sizing: border-box;
	border-radius: 8px;
	color: var(--neutral-000);
	display: inline-block;
	font-size: 10px;
	font-weight: 700;
	line-height: 8px;
	min-width: 16px;
	padding: 4px;
	position: absolute;
	top: -6px;
	right: -6px;
	vertical-align: middle;
}

.mapplic-switch {
	background-color: rgba(242, 242, 242, 0.6);
	box-sizing: content-box;
	border-radius: 8px;
	display: flex;
	min-width: 32px;
	height: 32px;
	padding: 4px;
	position: relative;
}

.mapplic-switch > .mapplic-switch-background {
	position: absolute;
	background-color: var(--neutral-000);
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
	border-radius: 6px;
	height: 32px;
	right: 4px;
	left: 4px;
	transition: left 0.2s;
}

.mapplic-switch > button {
	background: none;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	gap: 6px;
	font-size: 15px;
	color: var(--neutral-900);
	flex-grow: 1;
	padding: 8px;
	min-width: 32px;
	height: 100%;
	position: relative;
	transition: color 0.2s;
}

.mapplic-switch > button > span {
	color: var(--neutral-700);
}

.mapplic-switch button.mapplic-active {
	color: var(--neutral-900);
}

/* toggle */
.mapplic-toggle {
	color: var(--neutral-900);
	cursor: pointer;
	display: block;
	font-size: 14px;
	line-height: 20px;
	position: relative;
}

.mapplic-toggle-switch {
	position: absolute;
	display: inline-block;
	width: 32px;
	height: 20px;
	right: 0;
}

.mapplic-toggle-switch > input { display: none; }

.mapplic-toggle-switch > span {
	background-color: var(--neutral-200);
	border-radius: 16px;
	position: absolute;
	cursor: pointer;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	transition: 0.2s;
}

.mapplic-toggle-switch > span:before {
	background-color: var(--neutral-000);
	border-radius: 50%;
	position: absolute;
	content: '';
	height: 16px;
	width: 16px;
	left: 2px;
	top: 2px;
	transition: 0.2s;
}

.mapplic-toggle-switch > input:checked + span { background-color: var(--neutral-800); }
.mapplic-toggle-switch > input:checked + span:before { transform: translateX(12px); }

/* scrollbars */
.mapplic-element * {
	scrollbar-width: thin;
	scrollbar-color: var(--neutral-200) transparent;
}

.mapplic-element *::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.mapplic-element *::-webkit-scrollbar-track {
	background-color: transparent;
}

.mapplic-element *::-webkit-scrollbar-thumb {
	background-color: var(--neutral-200);
	border-radius: 20px;
	border: 3px solid transparent;
	box-sizing: border-box;
}

/* map */
.mapplic-layer * {
	pointer-events: none;
}

.mapplic-layer svg text {
	font-family: inherit;
}

.mapplic-layer svg *[id^=MLOC] > *[id],
.mapplic-layer svg *[id^=MLOC] > *[id] * {
	pointer-events: auto;
	transition: fill 0.2s;
}

.mapplic-layer svg .mapplic-highlight,
.mapplic-layer svg .mapplic-active {
	cursor: pointer;
}

/* geo maps */
.mapplic-layer svg[baseProfile] *[id^=MLOC] > *[id] {
	fill: var(--neutral-200);
	stroke: var(--neutral-400);
	stroke-width: 0.5;
}

/* routes */
.mapplic-wayfinding {
	display: flex;
	flex-direction: column;
	padding: 0;
	position: relative;
}

.mapplic-wayfinding.mapplic-active {
	border: 1px solid var(--neutral-100);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	background-color: var(--neutral-000);
	padding: 12px;
}

.mapplic-wayfinding-header {
	display: flex;
	padding: 4px;
	gap: 4px;
	justify-content: space-between;
}

.mapplic-wayfinding-content {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.mapplic-wayfinding-bar {
	display: flex;
	justify-content: space-between;
	gap: 8px;
}

.mapplic-wayfinding-bar-main {
	display: flex;
	gap: 8px;
}

.mapplic-close {
	all: unset;
	display: flex;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	top: 12px;
	right: 12px;
	opacity: 0.5;
	transition: 0.2s border-color, 0.2s background-color;
}

.mapplic-close:hover {
	opacity: 1;
}

.mapplic-wayfinding-position {
	margin-left: 4px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.mapplic-wayfinding-body {
	position: relative;
	gap: 8px;
	display: flex;
}

.mapplic-wayfinding-positions {
	display: flex;
	gap: 8px;
	flex-direction: column;
}

.mapplic-wayfinding-position:first-child > .mapplic-wayfinding-pin {
	border-radius: 6px;;
}

.mapplic-wayfinding-position > button {
	border: none;
	outline: 2px solid transparent;
	background-color: var(--neutral-100);
	border-radius: 6px;
	display: flex;
	padding: 8px;
	font-size: 14px;
	cursor: pointer;
	text-align: left;
	white-space: nowrap;
	transition: 0.2s all;
	width: 140px;
}

.mapplic-wayfinding-position > button:hover {
	background-color: var(--neutral-100) !important;
}

.mapplic-wayfinding-pin {
	border: 2px solid #000;
	box-sizing: content-box;
	border-radius: 5px 5px 5px 0;
	rotate: -45deg;
	width: 5px;
	height: 5px;
}

.mapplic-wayfinding-swap {
	background-color: var(--neutral-000);
	all: unset;
	color: var(--neutral-700);
	padding: 4px;
	rotate: 90deg;
	cursor: pointer;
}

.mapplic-wayfinding-dots {
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: absolute;
	left: 7px;
	top: 28px;
}

.mapplic-wayfinding-dots > * {
	background-color: var(--neutral-300);
	width: 3px;	
	height: 3px;
	border-radius: 50%;
}

/* animated path */
.mapplic-routes-dashed {
	animation: dash 200s linear infinite;
}

@keyframes dash {
	to {
		stroke-dashoffset: -1000;
	}
}




.mapplic-search {
    background-color: rgba(242, 242, 242, 0.6);
    border-radius: 8px;
    position: relative;
    padding: 8px; /* Espacio interno */
  }
  
  .mapplic-search > input {
    background: none;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--neutral-900);
    font-size: 16px !important;
    line-height: 20px !important;
    padding: 10px 16px; /* Ajustamos el padding para mejor visibilidad */
    width: 100%;
    transition: all 0.3s ease; /* Animación suave para focus */
  }
  
  .mapplic-search > input:focus {
    outline: 2px solid var(--primary); /* Indicador visual al enfocar */
    background-color: var(--neutral-000); /* Fondo más claro al escribir */
  }
  
  .mapplic-search > svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--neutral-500);
  }
  
  .mapplic-search > button {
    background: none;
    border: none;
    color: var(--neutral-900);
    cursor: pointer;
    line-height: 8px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    padding: 6px;
    position: absolute;
    transition: color 0.2s;
  }
  
  .mapplic-search > button:hover {
    color: var(--primary);
  }
  

  .mapplic-dir-item {
    display: flex;
    align-items: center;
    gap: 12px; /* Espaciado entre el thumbnail y el texto */
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: var(--neutral-050);
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
   
  }
  
  .mapplic-dir-item:hover {
    background-color: var(--neutral-000);
    border-color: var(--neutral-300);    
  }
  
  .mapplic-dir-item.mapplic-active {
    background-color: var(--primary);
    color: var(--neutral-000);
    border-color: var(--primary);
  }
  
  .mapplic-thumbnail {
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    background-color: var(--neutral-300); /* Fondo gris predeterminado */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--neutral-000); /* Texto blanco */
    overflow: hidden;
    flex-shrink: 0; /* Mantener el tamaño del thumbnail */
  }
  
  .mapplic-thumbnail.available {
    background-color: var(--success); /* Verde para disponibles */
  }
  
  .mapplic-thumbnail.rented {
    background-color: var(--error); /* Rojo para alquilados */
  }
  
  .mapplic-thumbnail > img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* La imagen llena el contenedor */
  }
  
  .mapplic-thumbnail > span {
    font-size: 16px;
  }

  .empty-icon {
	display: none;
  }

/* Clase del Tooltip personalizado */
.custom-tooltip {
	background-color: transparent !important; /* Fondo transparente */
	border: none;
	/* border: 2px solid rgb(255, 255, 255)!important;   
	border-radius: 0.5em; */
	box-shadow: none !important; /* Sin sombra */
	padding: 5 !important; /* Sin relleno extra */
	margin: auto;
	display: flex;
	justify-content: center; /* Centrar horizontalmente */
	align-items: center; /* Centrar verticalmente */
	
  }

 /* Contenedor para organizar el texto */
.box-label {		
	color: rgb(251, 249, 249);	
	line-height: 1.2;	
	display: inline-block; /* Asegura que sea un bloque centrado */
	text-align: center; /* Centra horizontalmente el texto */
	width: max-content; /* Ajusta el tamaño según el contenido */
	margin: 0 auto; /* Centrado automático */
	white-space: nowrap; /* Evita que el texto salte de línea */	
	
  }
  
  /* Estilo del nombre del box */
  .box-name {
	/* font-size: 0.9rem; Tamaño más grande para el nombre */
	font-weight: bold;
	display: flex;
	font-size: inherit;
  }
  
  /* Estilo del tamaño del box */
  .box-size {
	/*font-size: 0.8rem; Tamaño más pequeño para los metros */
	font-weight: normal;
	display: flex;
	font-size: inherit;
  }
  

  .menu-container {
	position: relative;
	width: 20%; /* Ancho expandido */
	transition: width 0.3s ease; /* Animación suave */
	overflow: hidden; /* Ocultar contenido desbordado */
	background-color: #f7f7f7;
	border-right: 1px solid #ddd;
  }
  
  .menu-container.collapsed {
	width: 0%; /* Ancho colapsado */
  }
  
  /* Botón para expandir/colapsar */
.toggle-button {
	position: absolute;
	top: 50%; /* Centrado verticalmente */
	right: -15px; /* Sobresale del borde derecho */
	transform: translateY(-50%);
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	transition: transform 0.3s ease;
  }
  
  .menu-container.collapsed .toggle-button {
	transform: translateY(-50%) rotate(180deg); /* Invertir la flecha */
  }