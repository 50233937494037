.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 101;
}

.leaflet-default-icon-path {
  background-image: url(leaflet/dist/images/marker-icon.png);
}

/* .leaflet-control-layers-toggle {
  background-image: url(/images/layers.png);
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/images/layers-2x.png);
} */


/* .image-overlay{
  background-color: #bb4040;
} */

.leaflet-overlay-pane {
  /* background-color: white;;
  width: 100vw;
  height: 100vh; */
}


.leaflet-tooltip {
  font-size: 16px;
}


.map-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: visible;
  /* padding: 20px; */
  /* border:1px red solid; */
  /* min-height: 800px; */
}

.map-wrapper {
  height: 100%;
  width: 100%;
}


/* .leaflet-image-layer.image-overlay{
  border: 1px red solid;
} */

/* 
.zoom-hover:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
} */